import { Injectable } from "@angular/core";
import { FacebookPixelService } from "./facebook-pixel.service";
import { GoogleTagManagerService } from "./google-tag-manager.service";
import { IUser } from "../../models/user/user.model";
import { EFacebookTrackEvent } from "../../enums/facebook-pixel-events.enum";
import { EGoogleTagEvents } from "../../enums/google-tag-manager-events.enum";

@Injectable()
export class TrackerService {
  constructor(
    private readonly facebookPixel: FacebookPixelService,
    private readonly googleTagManager: GoogleTagManagerService,
  ) {
  }

  async checkoutStart(paymentInfo: { price: number, currency: string, tx: number | string }) {
    this.facebookPixel.track(EFacebookTrackEvent.InitiateCheckout, {
      content_ids: [1],
      contents: [{id: 1, quantity: 1}],
      currency: paymentInfo.currency,
      num_items: 1,
      value: paymentInfo.price,
    });

    this.googleTagManager.track(EGoogleTagEvents.BeginCheckout, {
      currency: paymentInfo.currency,
      value: paymentInfo.price,
      items: [
        {
          item_id: 1,
          item_name: 'Premium Plan',
          price: paymentInfo.price,
          quantity: 1,
        }
      ],
    });
  }

  async checkoutCompleted(paymentInfo: { price: number, currency: string, tx: number | string }) {
    // content_ids, content_type, contents, currency, num_items, value
    this.facebookPixel.track(EFacebookTrackEvent.Purchase, {
      content_ids: [1],
      contents: [{id: 1, quantity: 1}],
      currency: paymentInfo.currency,
      num_items: 1,
      value: paymentInfo.price,
    });

    this.googleTagManager.track(EGoogleTagEvents.Purchase, {
      currency: paymentInfo.currency,
      value: paymentInfo.price,
      transaction_id: `T_${paymentInfo.tx}`,
      items: [
        {
          item_id: 1,
          item_name: 'Premium Plan',
          price: paymentInfo.price,
          quantity: 1,
        }
      ],
    });
  }

  async newRegistration(user?: IUser) {
    this.facebookPixel.track(EFacebookTrackEvent.CompleteRegistration);
    this.googleTagManager.track(EGoogleTagEvents.SignUp, {
      method: 'web'
    });
  }


}
