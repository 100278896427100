import { Injectable } from "@angular/core";
import { EFacebookTrackEvent } from "../../enums/facebook-pixel-events.enum";

@Injectable()
export class FacebookPixelService {
  constructor() {
  }

  track(event: EFacebookTrackEvent, extraOptions?: {}) {
    if (typeof fbq !== 'undefined') {
      try {
        fbq('track', event, extraOptions);
      } catch (e) {
      }
    } else {
      console.log(`Facebook pixel is not initialized.`);
    }
  }
}
