import { Injectable } from "@angular/core";
import { EGoogleTagEvents } from "../../enums/google-tag-manager-events.enum";

@Injectable()
export class GoogleTagManagerService {
  constructor() {
  }

  track(event: EGoogleTagEvents | string, extraOptions?: {}) {
    if (typeof gtag !== 'undefined') {
      try {
        gtag('event', event, extraOptions);
      } catch (e) {
      }
    }
  }
}
